import React, { Component } from "react"
import { graphql } from "gatsby"
import $ from 'jquery/dist/jquery'
import { Helmet } from "react-helmet"
import ReactHtmlParser from 'react-html-parser'
import MoreButton from "../components/more-button"
import Link  from '../components/delayed-gatsby-link';

class Page extends Component {
  constructor(props) {
    super(props);
    this._itemClicked = this.itemClicked.bind(this);
  }

  itemClicked(event){
    window.dispatchEvent( new Event('animateOut') );
  }

  componentDidMount() {
    var that = this;
    $('h4').click(function(e){
      var p = $(this).parent();
      p.toggleClass('open');
      if( p.hasClass('open') ){
        var h = that.getHeightByChildren( p );
        p.height(h);
      } else {
        p.attr('style', '');
      }
      
    });
  }

  getHeightByChildren(parent){
    var h = 0;
    parent.children().each(function(){
      if($(this).is(":visible")){ 
        h += $(this).outerHeight(true);
      }
    });

    return h;
  }

  render() {
    const StaticPage = this.props.data.wordpressPage

    if(StaticPage.slug === 'work'){
      const data = this.props.data
      data.allWordpressWpProjects.edges.sort(function(a, b) {
        return a.node.menu_order - b.node.menu_order;
      });

      return (
        <div className="page Work ColorKey" data-bgcolor="#ffffff">
          <Helmet title={StaticPage.title+' - illcreative'}> { ReactHtmlParser(StaticPage.yoast_head.replace(new RegExp('http://localhost', 'g'),'https://www.illcreative.com'))}</Helmet>
          <div className="main-content">
            <div className="main-wrap">
              {data.allWordpressWpProjects.edges.map(({ node }) => (
                <section className={'ProjectPreview no-padding-top no-padding-bottom no-padding-left no-padding-right fader ' +node.slug} key={node.slug} data-bgcolor={node.acf.primary_color}>
                  <Link delay={1000} className="inner taphover" to={'/work/'+node.slug} onClick={this._itemClicked} style={{ backgroundColor: node.acf.primary_color }}>
                    <div className="title-block">
                      <div className="text-wrap">
                        <h3 className="eyebrow fader">{node.title}</h3>
                        <h2 className="eyebrow-before fader" dangerouslySetInnerHTML={{__html: node.acf.title }}></h2>
                        <MoreButton/>
                      </div>
                    </div>
                    
                    <div className="image-block">
                      <img className="image desktop" src={ node.acf.preview_image.source_url } alt={node.acf.preview_image.alt_text}/>
                      <img className="image mobile" src={node.acf.preview_image.source_url.replace('.png' , '-768x768.png')} alt={node.acf.preview_image.alt_text}/>
                    </div>
                  </Link>
                </section>
              ))}
            </div>
          </div>
        </div>
      )

    } else {
      return (
        <div className={'page ColorKey ' + StaticPage.slug } data-bgcolor="#ffffff">
          <Helmet title={StaticPage.title+' - illcreative'}> { ReactHtmlParser(StaticPage.yoast_head.replace(new RegExp('http://localhost', 'g'),'https://www.illcreative.com'))}</Helmet>
          <div dangerouslySetInnerHTML={{__html:StaticPage.content}}></div>
        </div>
      )
    }



  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      slug
      content
      yoast_head
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    allWordpressWpProjects {
      edges {
        node {
          title
          slug
          menu_order
          acf {
            primary_color
            title
            preview_image {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`